$(function () {
    if ($(".regular-toc").length) {

        tocbot.init({
            tocSelector: ".regular-toc .toc-container",
            contentSelector: ".markdown-content",
            headingSelector: 'h1, h2, h3, h4',
            listClass: "nav",
            extraListClasses: "flex-column",
            linkClass: "nav-link",
            isCollapsedClass: "d-none",
            orderedList: false,
            headingsOffset: -$(".markdown-content").offset().top + 110,
            scrollSmoothOffset: -90,
            activeLinkClass: "active",
        });

        /* Once the toc is generated copy plain content into mobile toc container. */
        var mobile_toc = $(".regular-toc .toc-container ul")
            .clone()
            .appendTo(".mobile-toc .toc-container");

        /* Remove any dynamic, scrollspy related classes from copied toc. */
        mobile_toc.find(".d-none").removeClass("d-none");
        mobile_toc.find(".active").removeClass("active");

    }
});
